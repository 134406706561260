import React from "react";
import team_image1 from "../Static/Images/team/team1.jpg";
import team_image2 from "../Static/Images/team/team2.jpg";
import team_image3 from "../Static/Images/team/team3.jpg";
import team_image4 from "../Static/Images/team/team4.jpg";
import team_image5 from "../Static/Images/team/team5.jpg";

const Team_div = () => {
  return (
    <div className="team_div_main">
      <h1 className="title">Our Team</h1>
      <div className="cards">
        <div className="card">
          <div className="image">
            <img src={team_image4} loading="lazy"></img>
          </div>
          <div className="text">
            <h1>Mrs. Krishna Wadher</h1>
            <p>CEO</p>
          </div>
        </div>
        <div className="card">
          <div className="image">
            <img src={team_image5} loading="lazy"></img>
          </div>
          <div className="text">
            <h1>Mr. Narendra Olhan</h1>
            <p>Strategic Director</p>
          </div>
        </div>{" "}
        <div className="card">
          <div className="image">
            <img src={team_image1} loading="lazy"></img>
          </div>
          <div className="text">
            <h1>Mr. Shivandra Hitt</h1>
            <p>Managing Director</p>
          </div>
        </div>
        <div className="card">
          <div className="image">
            <img src={team_image2} loading="lazy"></img>
          </div>
          <div className="text">
            <h1>Mr. Jayesh Wadher</h1>
            <p>Creative Director</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team_div;
