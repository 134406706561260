export const courses = [
  {
    name: "Basic Makeup Course",
    image:
      "https://images.unsplash.com/photo-1487412912498-0447578fcca8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Foundations of Makeup Artistry",
    duration: "8 weeks",
    description:
      "Master the fundamentals of makeup application, color theory, and skincare to kickstart your career as a makeup artist.",
    brief:
      "Our Basic Makeup Course is designed for beginners who want to learn the essentials of makeup artistry. Led by industry professionals, this course covers basic techniques, product knowledge, and hygiene practices. You'll gain hands-on experience in creating natural to glamorous looks for various occasions, setting a solid foundation for your makeup career.",
    price: "150",
    outcome: "Certified Makeup Artist",
    audience: "Beginners",
    instructor: "Jane Smith",
    destination: "Online",
    requirements: "NIL",
    level: "Beginner",
    language: "English",
    category: "Makeup",
    certification: "Yes",
    key: "002",
    kit_content: [
      "1x Eyeshadow Palette",
      "1x Foundation",
      "1x Concealer",
      "1x Blush",
      "1x Lipstick Set",
      "1x Brush Set",
      "1x Makeup Sponge",
      "1x Makeup Case",
    ],
  },

  {
    name: "Advanced Makeup Course",
    image:
      "https://images.unsplash.com/photo-1500840216050-6ffa99d75160?q=80&w=1797&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Artistry Techniques",
    duration: "12 weeks",
    description:
      "Elevate your makeup skills with advanced techniques in contouring, highlighting, and specialty makeup applications.",
    brief:
      "Our Advanced Makeup Course is tailored for makeup artists looking to refine their skills and expand their repertoire. Led by industry experts, this course delves into advanced techniques such as editorial, avant-garde, and special effects makeup. Through hands-on workshops and personalized feedback, you'll elevate your artistry and stand out in the competitive beauty industry.",
    price: "300",
    outcome: "Master Makeup Artist",
    audience: "Intermediate/Advanced",
    instructor: "Michael Johnson",
    destination: "Offline",
    requirements: "Basic Makeup Certification",
    level: "Advanced",
    language: "English",
    category: "Makeup",
    certification: "Yes",
    key: "003",
    kit_content: [
      "1x Contour Palette",
      "1x Specialty Makeup Kit",
      "1x Airbrush System",
      "1x Special Effects Makeup Kit",
      "1x Makeup Brushes Set",
      "1x Makeup Artist Case",
    ],
  },
  {
    name: "Multimedia Makeup Course",
    image:
      "https://st3.depositphotos.com/13194036/17300/i/450/depositphotos_173004264-stock-photo-woman-applying-colorful-eyeshadows.jpg",
    title: "Exploring Makeup Across Media",
    duration: "10 weeks",
    description:
      "Dive into the world of multimedia makeup, from film and television to editorial and runway, and learn how to adapt your skills for different mediums.",
    brief:
      "Our Multimedia Makeup Course is designed for makeup artists seeking to expand their expertise into various media platforms. Led by industry professionals, this course covers makeup techniques tailored for film, television, photography, and editorial work. With hands-on projects and industry insights, you'll learn to create captivating looks that translate seamlessly across different media formats.",
    price: "250",
    outcome: "Multimedia Makeup Artist",
    audience: "Intermediate",
    instructor: "Sophia Lee",
    destination: "Online",
    requirements: "Basic Makeup Certification",
    level: "Intermediate",
    language: "English",
    category: "Makeup",
    certification: "Yes",
    key: "004",
    kit_content: [
      "1x HD Makeup Kit",
      "1x Airbrush Makeup Set",
      "1x Special Effects Makeup Kit",
      "1x Editorial Makeup Palette",
      "1x Makeup Brushes Set",
      "1x Makeup Artist Rolling Case",
    ],
  },
  {
    name: "Eyelash Extension Course",
    image:
      "https://images.unsplash.com/photo-1633346152343-5486573d3d50?q=80&w=2003&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Eyelash Extensions",
    duration: "2 days",
    description:
      "Learn the art and technique of eyelash extensions, from classic to volume lashes, and enhance your skills to become a certified lash technician.",
    brief:
      "Our Eyelash Extension Course is designed for beauty enthusiasts and professionals looking to specialize in eyelash enhancements. Led by certified trainers, this intensive course covers lash extension application, safety protocols, and client consultation. With hands-on practice and guidance, you'll gain the expertise to create stunning lash looks and grow your clientele.",
    price: "350",
    outcome: "Certified Lash Technician",
    audience: "Intermediate",
    instructor: "Emily Brown",
    destination: "Offline",
    requirements: "NIL",
    level: "Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "005",
    kit_content: [
      "1x Mixed Tray of Lash Extensions",
      "1x Adhesive",
      "1x Tweezers Set",
      "1x Gel Eye Pads",
      "1x Lash Primer",
      "1x Lash Cleanser",
      "1x Lash Extension Kit Case",
    ],
  },
  {
    name: "Keratin Lash Lift Course",
    image:
      "https://images.unsplash.com/photo-1493422884938-abd42cfa0f29?q=80&w=2040&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Keratin Lash Lifts",
    duration: "1 day",
    description:
      "Discover the technique of keratin lash lifting to enhance natural lashes, and offer clients a low-maintenance lash enhancement solution.",
    brief:
      "Our Keratin Lash Lift Course is perfect for beauty professionals seeking to offer clients a natural lash enhancement alternative. Led by experienced trainers, this course covers lash lift procedures, product knowledge, and safety measures. You'll learn to create lifted, curled lashes that last, providing clients with a fuss-free beauty solution.",
    price: "250",
    outcome: "Certified Lash Lift Technician",
    audience: "Intermediate",
    instructor: "Jessica Miller",
    destination: "Offline",
    requirements: "NIL",
    level: "Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "006",
    kit_content: [
      "1x Lash Lifting Kit",
      "1x Silicone Rods Set",
      "1x Lash Lifting Adhesive",
      "1x Lash Lifting Tool Set",
      "1x Lash Conditioning Serum",
      "1x Lash Lift Kit Case",
    ],
  },
  {
    name: "Brow Tint Course",
    image:
      "https://images.unsplash.com/photo-1495707800306-e240c5a0d65f?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Brow Tinting Techniques",
    duration: "1 day",
    description:
      "Learn the art of brow tinting, from color selection to application techniques, and offer clients perfectly defined brows.",
    brief:
      "Our Brow Tint Course is designed for beauty professionals interested in mastering brow enhancement techniques. Led by industry experts, this course covers brow tinting procedures, color theory, and shaping methods. You'll gain hands-on experience in creating customized brow looks that complement your clients' features, boosting their confidence.",
    price: "200",
    outcome: "Certified Brow Tint Technician",
    audience: "Intermediate",
    instructor: "Sarah Thompson",
    destination: "Offline",
    requirements: "NIL",
    level: "Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "007",
    kit_content: [
      "1x Brow Tint Kit",
      "1x Developer",
      "1x Tint Brushes Set",
      "1x Mixing Dish",
      "1x Tint Remover",
      "1x Brow Tint Kit Case",
    ],
  },
  {
    name: "Microblading Course",
    image:
      "https://images.unsplash.com/photo-1585885970325-81cba4494c27?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Microblading Artistry",
    duration: "3 days",
    description:
      "Discover the technique of microblading to create natural-looking, semi-permanent eyebrows, and enhance your skills to become a certified microblading artist.",
    brief:
      "Our Microblading Course is ideal for beauty professionals interested in offering semi-permanent brow enhancement services. Led by certified trainers, this comprehensive course covers microblading techniques, safety protocols, and client aftercare. With hands-on practice and guidance, you'll learn to create flawless, natural-looking brows that last, enhancing your clients' beauty and confidence.",
    price: "800",
    outcome: "Certified Microblading Artist",
    audience: "Intermediate/Advanced",
    instructor: "Emma Wilson",
    destination: "Offline",
    requirements: "NIL",
    level: "Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "008",
    kit_content: [
      "1x Microblading Kit",
      "1x Disposable Microblading Pen",
      "1x Pigment Set",
      "1x Microblading Blades Set",
      "1x Numbing Cream",
      "1x Microblading Kit Case",
    ],
  },
  {
    name: "Hair Coloring Course",
    image:
      "https://images.unsplash.com/photo-1470259078422-826894b933aa?q=80&w=2074&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Master Hair Colouring",
    duration: "16 hours",
    description:
      "Learn the art of hair coloring from the best in the industry. Our course will teach you everything you need to know to become a professional hair colorist.",
    brief:
      "Hair Coloring Mastery is a comprehensive course designed for beginners who aspire to become professional hair colorists. Led by industry expert John Doe, this course covers everything from basic color theory to advanced coloring techniques, ensuring you develop a strong foundation in hair coloring. With a focus on hands-on learning and personalized feedback, you'll gain confidence in creating stunning hair color transformations for your clients. Upon completion, you'll receive certification as a Professional Hair Colorist, equipped with the skills and knowledge to thrive in the competitive beauty industry.",
    price: "200",
    outcome: "Professional Hair Colorist",
    audience: "Beginners",
    instructor: "John Doe",
    destination: "Offline",
    requirements: "NIL",
    level: "Beginner",
    language: "English",
    category: "Hair",
    certification: "Yes",
    key: "001",
    kit_content: [
      "12x Colour Tube",
      "1x 20volume developer",
      "1x 30volume developer",
      "1x 40volume developer",
      "1x foil box",
      "1x tail comb",
      "1x Hair Bleach",
      "1x client apron",
      "1x lab coat",
      "1x Head cap",
    ],
  },
  {
    name: "Micro Needling Course",
    image:
      "https://images.unsplash.com/photo-1512290746430-3ffb4fab31bc?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Microneedling Techniques",
    duration: "2 days",
    description:
      "Explore the benefits of microneedling for skin rejuvenation and learn advanced techniques to become a certified microneedling specialist.",
    brief:
      "Our Micro Needling Course is designed for skincare professionals seeking to enhance their skills in skin rejuvenation treatments. Led by experienced practitioners, this course covers microneedling techniques, skin anatomy, and safety protocols. With hands-on practice and guidance, you'll learn to perform microneedling procedures effectively, addressing various skin concerns and delivering optimal results for your clients.",
    price: "500",
    outcome: "Certified Microneedling Specialist",
    audience: "Intermediate/Advanced",
    instructor: "Dr. Rebecca Jones",
    destination: "Offline",
    requirements: "Basic Skincare Certification",
    level: "Intermediate",
    language: "English",
    category: "Skincare",
    certification: "Yes",
    key: "009",
    kit_content: [
      "1x Microneedling Pen",
      "1x Microneedling Needle Cartridges Set",
      "1x Topical Anesthetic",
      "1x Hyaluronic Acid Serum",
      "1x Microneedling Kit Case",
    ],
  },
  {
    name: "Hair Extensions Course",
    image:
      "https://images.unsplash.com/photo-1522337360788-8b13dee7a37e?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Hair Extensions",
    duration: "2 days",
    description:
      "Learn the art of hair extensions, from installation techniques to maintenance, and become a certified hair extension specialist.",
    brief:
      "Our Hair Extensions Course is perfect for hairstylists looking to expand their service offerings. Led by experienced educators, this comprehensive course covers various hair extension methods, including tape-in, fusion, and micro-link. You'll learn proper consultation, application, and maintenance techniques, enabling you to create natural-looking, long-lasting hair extensions for your clients.",
    price: "600",
    outcome: "Certified Hair Extension Specialist",
    audience: "Intermediate/Advanced",
    instructor: "Alexandra Brown",
    destination: "Offline",
    requirements: "Basic Hairdressing Certification",
    level: "Intermediate",
    language: "English",
    category: "Hair",
    certification: "Yes",
    key: "010",
    kit_content: [
      "1x Hair Extension Starter Kit",
      "1x Fusion Bonding Tool",
      "1x Tape-In Hair Extensions Set",
      "1x Micro-Link Hair Extensions Set",
      "1x Hair Extension Remover",
      "1x Hair Extension Kit Case",
    ],
  },
  {
    name: "Hair Cutting Course",
    image:
      "https://images.unsplash.com/photo-1524157908232-f591d969233b?q=80&w=1948&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Hair Cutting Techniques",
    duration: "4 weeks",
    description:
      "Enhance your hair cutting skills with advanced techniques in precision cutting, layering, and texturizing, and become a certified hair cutting specialist.",
    brief:
      "Our Hair Cutting Course is designed for hairstylists seeking to elevate their cutting skills. Led by industry experts, this comprehensive course covers advanced cutting techniques, including precision cuts, layering, and texturizing. Through hands-on practice and personalized feedback, you'll develop the expertise to create versatile haircuts that suit your clients' individual styles and preferences.",
    price: "400",
    outcome: "Certified Hair Cutting Specialist",
    audience: "Intermediate/Advanced",
    instructor: "David Johnson",
    destination: "Offline",
    requirements: "Basic Hairdressing Certification",
    level: "Intermediate",
    language: "English",
    category: "Hair",
    certification: "Yes",
    key: "011",
    kit_content: [
      "1x Professional Hair Cutting Scissors",
      "1x Thinning Shears",
      "1x Hair Cutting Comb",
      "1x Hair Cutting Cape",
      "1x Hair Sectioning Clips Set",
      "1x Hair Cutting Kit Case",
    ],
  },
  {
    name: "Advanced Skincare Course",
    image:
      "https://images.unsplash.com/photo-1489278353717-f64c6ee8a4d2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Advanced Skincare Techniques",
    duration: "6 weeks",
    description:
      "Expand your skincare knowledge with advanced treatments and techniques, including chemical peels, microdermabrasion, and LED therapy, and become a certified advanced skincare specialist.",
    brief:
      "Our Advanced Skincare Course is perfect for estheticians seeking to enhance their expertise in advanced skincare treatments. Led by skincare professionals, this course covers a range of advanced techniques, including chemical peels, microdermabrasion, and LED therapy. With hands-on training and theoretical knowledge, you'll learn to address various skin concerns and deliver tailored skincare solutions for your clients, achieving optimal results.",
    price: "700",
    outcome: "Certified Advanced Skincare Specialist",
    audience: "Intermediate/Advanced",
    instructor: "Dr. Sophia Roberts",
    destination: "Offline",
    requirements: "Basic Esthetics Certification",
    level: "Intermediate",
    language: "English",
    category: "Skincare",
    certification: "Yes",
    key: "012",
    kit_content: [
      "1x Chemical Peel Kit",
      "1x Microdermabrasion Machine",
      "1x LED Therapy Device",
      "1x Skincare Serums Set",
      "1x Advanced Skincare Kit Case",
    ],
  },
  {
    name: "Nail Technician Course",
    image:
      "https://images.unsplash.com/photo-1632345031435-8727f6897d53?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Nail Artistry",
    duration: "4 weeks",
    description:
      "Learn the art of nail care, from basic manicures and pedicures to advanced nail art techniques, and become a certified nail technician.",
    brief:
      "Our Nail Technician Course is designed for individuals interested in pursuing a career in nail care and artistry. Led by experienced nail technicians, this comprehensive course covers nail anatomy, hygiene practices, and various nail enhancement techniques. You'll learn to perform manicures, pedicures, and create stunning nail art designs, providing clients with personalized and creative nail services.",
    price: "500",
    outcome: "Certified Nail Technician",
    audience: "Beginners/Intermediate",
    instructor: "Sophie Johnson",
    destination: "Offline",
    requirements: "NIL",
    level: "Beginner/Intermediate",
    language: "English",
    category: "Nails",
    certification: "Yes",
    key: "013",
    kit_content: [
      "1x Manicure Set",
      "1x Pedicure Set",
      "1x UV Gel Nail Kit",
      "1x Nail Art Brushes Set",
      "1x Nail Polish Set",
      "1x Nail Technician Kit Case",
    ],
  },
  {
    name: "Threading Course",
    image:
      "https://images.unsplash.com/photo-1519415387722-a1c3bbef716c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Threading Techniques",
    duration: "2 days",
    description:
      "Learn the ancient art of threading for hair removal, and become a certified threading specialist.",
    brief:
      "Our Threading Course is ideal for beauty professionals looking to offer threading services in their salon or spa. Led by experienced threading experts, this course covers threading techniques for facial hair removal, including eyebrows, upper lip, and chin. You'll learn the art of precise hair removal using thread, providing clients with smooth and defined brows and facial areas.",
    price: "250",
    outcome: "Certified Threading Specialist",
    audience: "Beginners/Intermediate",
    instructor: "Aisha Patel",
    destination: "Offline",
    requirements: "NIL",
    level: "Beginner/Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "014",
    kit_content: [
      "1x Threading Thread",
      "1x Threading Tools Set",
      "1x Astringent",
      "1x Tweezers",
      "1x Threading Kit Case",
    ],
  },
  {
    name: "Henna / Mehndi Course",
    image:
      "https://images.unsplash.com/photo-1505932794465-147d1f1b2c97?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Mastering Henna Artistry",
    duration: "2 days",
    description:
      "Explore the art of henna/mehndi designs, from traditional to contemporary styles, and become a certified henna artist.",
    brief:
      "Our Henna / Mehndi Course is perfect for individuals interested in learning the ancient art of henna body art. Led by experienced henna artists, this course covers henna application techniques, design creation, and aftercare. You'll learn to create intricate and beautiful henna designs, providing clients with unique and memorable body art experiences.",
    price: "300",
    outcome: "Certified Henna Artist",
    audience: "Beginners/Intermediate",
    instructor: "Riya Gupta",
    destination: "Offline",
    requirements: "NIL",
    level: "Beginner/Intermediate",
    language: "English",
    category: "Beauty",
    certification: "Yes",
    key: "015",
    kit_content: [
      "1x Henna Powder",
      "1x Essential Oils",
      "1x Design Stencils Set",
      "1x Applicator Cones",
      "1x Henna Aftercare Kit",
      "1x Henna Artist Kit Case",
    ],
  },
];

export default courses;

export const services = [
  {
    name: "Classic Facial",
    description:
      "A relaxing facial treatment to cleanse, exfoliate, and nourish the skin.",
    image:
      "https://images.unsplash.com/photo-1598556146869-aeb261893c35?q=80&w=2097&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 80,
    duration: "60 mins",
    availability: ["Monday", "Wednesday", "Friday"],
  },
  {
    name: "Manicure and Pedicure",
    description:
      "A pampering session for your hands and feet, including nail shaping, cuticle care, and polish.",
    image:
      "https://images.unsplash.com/photo-1610992015732-2449b76344bc?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 50,
    duration: "90 mins",
    availability: ["Tuesday", "Thursday", "Saturday"],
  },
  {
    name: "Eyebrow Shaping",
    description:
      "Expert eyebrow shaping to enhance your natural arch and frame your face.",
    image:
      "https://images.unsplash.com/photo-1530695440407-21fef47230b1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 20,
    duration: "30 mins",
    availability: ["Monday", "Wednesday", "Friday", "Saturday"],
  },
  {
    name: "Hair Styling",
    description:
      "Professional hair styling for any occasion, from sleek blowouts to elegant updos.",
    image:
      "https://images.unsplash.com/photo-1557353425-6c61136de070?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 60,
    duration: "45 mins",
    availability: ["Tuesday", "Thursday", "Saturday"],
  },
  {
    name: "Makeup Application",
    description:
      "Expert makeup application for weddings, events, or everyday glam.",
    image:
      "https://images.unsplash.com/photo-1583241800967-3b926edea1f2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 70,
    duration: "60 mins",
    availability: ["Monday", "Wednesday", "Friday", "Sunday"],
  },
  {
    name: "Waxing Services",
    description:
      "Effective waxing services for smooth and hair-free skin, including legs, arms, and bikini area.",
    image:
      "https://images.unsplash.com/photo-1594051843850-2caa9704b28d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 40,
    duration: "Varies",
    availability: ["Tuesday", "Thursday", "Saturday"],
  },
  {
    name: "Eyelash Extensions",
    description:
      "Enhance your lashes with professional eyelash extensions for a fuller, more dramatic look.",
    image:
      "https://images.unsplash.com/photo-1492618269284-653dce58fd6d?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    price: 100,
    duration: "90 mins",
    availability: ["Monday", "Wednesday", "Friday"],
  },
];
