import React from "react";
import Course_detail_div from "../Components/Course_detail_div";

const Coursedetails = () => {
  return (
    <div className="coursedetails_div_main">
      <Course_detail_div />
    </div>
  );
};

export default Coursedetails;
