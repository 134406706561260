import React, { useState, useEffect } from "react";
import Special_div from "./Special_div";
import courses from "../Data/Data";
import { Link } from "react-router-dom";

const Introdiv = () => {
  const [index, setIndex] = useState(0);
  const total = 2; // Total number of carousel items

  const updateCarousel = (direction) => {
    let newIndex = index + direction;
    if (newIndex < 0) newIndex = total - 1;
    else if (newIndex === total) newIndex = 0;
    setIndex(newIndex);
  };

  useEffect(() => {
    updateCarousel(0); // Initialize carousel
  }, []);

  return (
    <div className="intro-div-main">
      <Special_div />

      <div className="courses-div">
        <h1 className="title" style={{ textAlign: "center" }}>
          Our Courses
        </h1>

        <div className="cards">
          <div className="card">
            <div className="image">
              <img
                src="https://images.unsplash.com/photo-1581182800629-7d90925ad072?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </div>
            <div className="content">
              <h2>Makeup Courses</h2>
              <ul>
                {courses
                  .filter((course) => course.category === "Makeup")
                  .slice(0, 3)
                  .map((course) => (
                    <li key={course.name}>{course.name}</li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img
                src="https://images.unsplash.com/photo-1582095133179-bfd08e2fc6b3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </div>
            <div className="content">
              <h2>Beauty Courses</h2>
              <ul>
                {courses
                  .filter((course) => course.category === "Beauty")
                  .slice(0, 3)
                  .map((course) => (
                    <li key={course.name}>{course.name}</li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="card">
            <div className="image">
              <img
                src="https://images.unsplash.com/photo-1572743686183-729b40b9230e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </div>
            <div className="content">
              <h2>Hair Courses</h2>
              <ul>
                {courses
                  .filter((course) => course.category === "Hair")
                  .slice(0, 3)
                  .map((course) => (
                    <li key={course.name}>{course.name}</li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <Link to="/courses" className="read-more">
          VIEW ALL
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Link>
      </div>
    </div>
  );
};

export default Introdiv;
