import React from "react";
import About_div_main from "../Components/About_div_main";

const About = () => {
  return (
    <>
      <About_div_main />
    </>
  );
};

export default About;
